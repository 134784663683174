import './Footer.css';

function Footer() {
    return (
        <div class="footer">
            <p class="copyright">made by Alvin Zhou, © 2023</p>
        </div>
    )
}

export default Footer;