import './App.css';

import Header from './Header/Header.js'
import Work from './Work/Work.js'
import Projects from './Projects/Projects.js'
import AboutMe from "./AboutMe/AboutMe.js"
import Footer from "./Footer/Footer.js"


function App() {
  return (
      <div class="site-wrap">
            <Header/>
            <AboutMe/>
            <Work/>
            <Projects/>
            <Footer/>
      </div>
  );
}

export default App;