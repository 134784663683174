import "./IconBar.css"

function IconBar() {
    return (
        <div class="icon-bar">
            <a
                href="https://github.com/alvzhou"
                class="icon social-buttons__button social-button social-button--github"
                aria-label="GitHub"
            >       
                <span class="social-button__inner">
                    <i class="fab fa-github"></i>
                </span>
            </a>
            <a
                    href="mailto:alvinzhou0@gmail.com"
                    class="icon social-buttons__button social-button social-button--mail"
                    aria-label="Facebook"
                >
                    <span class="social-button__inner">
                        <i class="fas fa-envelope"></i>
                    </span>
             </a>
            <a
                    href="https://www.linkedin.com/in/alvzhou/"
                    class="icon social-buttons__button social-button social-button--linkedin"
                    aria-label="LinkedIn"
                >
                    <span class="social-button__inner">
                        <i class="fab fa-linkedin-in"></i>
                    </span>
            </a>
            {/* <a
                    href="https://www.instagram.com/ansonchvng/"
                    class="icon social-buttons__button social-button social-button--instagram"
                    aria-label="SnapChat"
                >
                    <span class="social-button__inner">
                        <i class="fab fa-instagram"></i>
                    </span>
            </a> */}
            <a
                    href="https://open.spotify.com/playlist/0vvXsWCC9xrXsKd4FyS8kM?si=6HjReCECQO6aT5vO5F_r2g"
                    class="icon social-buttons__button social-button social-button--steam"
                    aria-label="SnapChat"
                >
                    <span class="social-button__inner">
                        <i class="fab fa-spotify"></i>
                    </span>
            </a>
        </div>
    )
}


export default IconBar;