import "./NavBar.css"
import resume from "../assets/resume.pdf"

function NavBar() {
    return (
        <div class="navBar">
            <a href="#about" class="navItem">/about-me</a>
            <a href="#work" class="navItem">/work</a>
            <a href="#projects" class="navItem">/projects</a>
            <a href={resume} class="navItem">/resume</a>
        </div>
    )
}

export default NavBar;