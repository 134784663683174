import "./AboutMe.css"
import ReactJson from "react-json-view"
import {meJSON, paletteImages} from "../assets/data.js"

const jsonStyle = {
    margin: '1%',
    padding: '2%',
    borderRadius: '10px',
    minWidth: "40%",
    width: "100%"
}
function AboutMe() {
    return (
        <div class="about-me-container" id="about">
            <h1>About Me</h1>
            <div class="about-me-content">
                <div class="about-me-content-upper">
                    <ReactJson
                            src={meJSON}
                            theme='ocean'
                            name={false}
                            iconStyle='square'
                            style={jsonStyle}
                            enableClipboard={false}
                            displayDataTypes={false}
                    />
                    <div class="image-palette-wrapper">
                        {paletteImages.map((image) => {
                            return <img alt="paletteImage" class="paletteImage" src={image}/>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutMe;