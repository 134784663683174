import { useEffect } from "react";
import "./MeObject.css"


function MeObject() {
    useEffect(() => {
        var words = document.getElementsByClassName('word');
        var wordArray = [];
        var currentWord = 0;

        words[currentWord].style.opacity = 1;

        for (var i = 0; i < words.length; i++) {
            splitLetters(words[i]);
        }

        function changeWord() {
            var cw = wordArray[currentWord];
            var nw = currentWord === words.length-1 ? wordArray[0] : wordArray[currentWord+1];

            for (var i = 0; i < cw.length; i++) {
                animateLetterOut(cw, i);
            }
        
            for (var j = 0; j < nw.length; j++) {
                nw[j].className = 'letter behind';
                nw[0].parentElement.style.opacity = 1;
                animateLetterIn(nw, j);
            }
        
            currentWord = (currentWord === wordArray.length-1) ? 0 : currentWord+1;
        }

        function animateLetterOut(cw, i) {
            setTimeout(function() {
                    cw[i].className = 'letter out';
            }, i*80);
        }

        function animateLetterIn(nw, i) {
            setTimeout(function() {
                    nw[i].className = 'letter in';
            }, 340+(i*80));
        }

        function splitLetters(word) {
            var content = word.innerHTML;
            var split = content.split(":");
            var wordOne = split[0];
            var wordTwo = split[1];

            word.innerHTML = '';
            var letters = [];

            for (var i = 0; i < split[0].length; i++) {
                var letter = document.createElement('span');
                letter.className = 'letter';
                letter.innerHTML = wordOne.charAt(i);
                word.appendChild(letter);
                letters.push(letter);
            }

            var colon = document.createElement('span');
            colon.className = 'letter';
            colon.innerHTML = `:`
            word.appendChild(colon);
            letters.push(colon);

            var color = '#e2e2e2'
            var blueCount = 0;

            for (var j = 0; j < split[1].length; j++) {
                letter = document.createElement('span');
                letter.className = 'letter';
                letter.innerHTML = wordTwo.charAt(j);
                if(wordTwo.charAt(j) === "_") {
                    letter.style.visibility = 'hidden';
                }
                if (wordTwo.charAt(j) === "*") {
                    letter.style.display = 'none';
                    color = '#2c7ad6';
                    blueCount = 5;
                }
                if (wordTwo.charAt(j) === "[" || wordTwo.charAt(j) === "]" || wordTwo.charAt(j) === ",") {
                    color = '#e2e2e2';
                }
                word.appendChild(letter);
                letter.style.color=color;
                letters.push(letter);
                blueCount--;
                color = blueCount > 0? '#2c7ad6' : '#ce9178';
            }
        
            wordArray.push(letters);
        }

        changeWord();
        setInterval(changeWord, 5000);
    }, [])

    return (
        <div class="descrip">
                <p class="decl">
                    <span style={{color: '#2c7ad6'}}> const </span>
                    <span style={{color: '#729db3'}}> me </span>
                    &#123;
                </p>
                    <div class="fields">
                        <p class="word">
                            name:_"Alvin_Zhou"
                        </p>
                        <p class="word">
                            school:_"UBC"
                        </p>
                        <p class="word">
                            major:_"CS"
                        </p>
                    </div>
                <p class="decl"> &#125;</p>
        </div>

    )
}

export default MeObject;