import ca from "./ca.jpg"
import moz from "./moz_logo.jpg"
import onTraccr from "./onTraccr.png"
import one from "./1.jpg"
import two from "./2.jpg"
import three from "./3.jpg"
import four from "./4.jpg"

export const paletteImages = [
    one, two, three, four
]

export const WORK = [
    {
        title: 'Full Stack Developer Intern',
        company: 'OnTraccr Technologies',
        date: 'May. 2023 - Sep. 2023',
        duties: [
                "An early stage construction startup that focuses on automating workflow software for construction companies!",
                "Developed features relating to front-end in React, back-end in Node.js, and React Native in Mobile"
        ],
        logo: onTraccr,
        tech: ["React", "React Native", "Redux", "Node.js", "MySQL"]
    },
    {
        title: 'Software Developer Intern',
        company: 'Crypto-Arsenal',
        date: 'Jan. 2023 - May. 2023',
        duties: [
                'Crypto-Arsenal is an online trading platform to test and use strategies on the crypto-market, used by quants and daily traders!',
                'I was responsible for building the Discord expansion, and establishing the push notification and verification systems to allow users to join the server',
        ],
        logo: ca,
        tech: ["TypeScript", "Python", "Kubernetes", "Discord.js"]
    },
    {
        title: 'Software Engineer Intern',
        company: 'Moz',
        date: 'May. 2022 - Dec. 2022',
        duties: [
            'Was part of the Data Services Team that manages that STAT Application, used by large companies such as Uber, Amazon, and Pinterest to manage and track SEO data'
        ],
        logo: moz,
        tech: ["Ruby", "Go", "MySQL", "Docker", "AWS"]
    }
]

export const PROJECTS = [
    {
        title: 'Parking and Research Inventory Project',
        summary: "An Google Maps displaying current pricing and parking info",
        winner: false,
        duties: [
              "Was part of the GrydPark subdivision, where I developed a webscraper using Python's Selenium Library",
              "Created a data visualization in Google Maps for non-technical stakeholders to utilize and access the data!"
        ],
        links: {
            github: "https://github.com/alvzhou",
            live: "",
        },
        tech: ["Python", "Selenium", "Google Maps"]
    },
    {
        title: 'Moodify',
        summary: "A music reccomender based your mood!",
        duties: [
                'Built using React, Node.js and Express.',
                'Used Google Vision API to convert JPG images of facial experssions into data!',
        ],
        links: {
            github: "https://github.com/aaronchan73/moodify",
            devpost: "",
        },
        tech: ["JavaScript", "React", "Node.js", "Express", "Google Cloud Vision API"]
    },
    {
        title: 'DayApp Interface',
        summary: "A tracker and interface for tracking daily itinerary",
        winner: false,
        duties: [
                'My first Project! Created using Java and the Java Swing Framework'
        ],
        links: {
            github: "https://github.com/alvzhou/DayApp-Interface",
            devpost: ""
        },
        tech: ["Java", "JUnit"]
    },
]

export const meJSON = {
    name: "Alvin Zhou",
    school: "UBC",
	work: {
		previously: "OnTraccr",
        title: "Full Stack Developer"
	},
    currently: ["in a school term", "sponsorship @ nwPlus"],
    interests: [
        "weightlifting",
        "mahjong",
        "NBA",
        "volleyball",
    ],
    contact: {
        email: "alvinzhoulo@gmail.com",
        phone: "+1 778-889-0233",
        github: "https://github.com/alvzhou",
    }
}